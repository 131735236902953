import { ApiError, commonFetch } from '../apiHelper';
import { BranchData } from './branchTypes';

export class BranchApi {
  static getUserBranches = async (): Promise<BranchData[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/current/branches`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}
