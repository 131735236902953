import produce from 'immer';
import { RbacRole } from 'services/rbac-manager/interfaces';
import { UserProfile } from 'api/user-profile/userProfileTypes';
import { AuthenticationUser } from 'api/authentication/authenticationTypes';

export interface AuthContextState {
  userProfile?: UserProfile,
  userRolePermissions?: RbacRole,
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  sessionUser?: AuthenticationUser,
  currentUser?: AuthenticationUser,
}

export const initialState = (): AuthContextState => ({
  userProfile: undefined,
  isAuthenticating: true,
  isAuthenticated: false,
  sessionUser: undefined,
});

export const STORE_USER_PROFILE = 'STORE_USER_PROFILE';
export const storeUserProfile = (userProfile: any) => ({
  type: STORE_USER_PROFILE, payload: { userProfile },
});

export const STORE_USER_PERMISSIONS = 'STORE_USER_PERMISSIONS';
export const storeUserPermissions = (rolePermissions: RbacRole) => ({
  type: STORE_USER_PERMISSIONS, payload: { rolePermissions },
});

export const SET_AUTHENTICATING = 'SET_AUTHENTICATING';
export const setAuthenticating = (authenticating: boolean) => ({
  type: SET_AUTHENTICATING, payload: { authenticating },
});

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const setAuthenticated = (authenticated: boolean) => ({
  type: SET_AUTHENTICATED, payload: { authenticated },
});

export const SET_SESSION_USER = 'SET_SESSION_USER';
export const setSessionUser = (user?: AuthenticationUser) => ({
  type: SET_SESSION_USER, payload: { user },
});

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = (user?: AuthenticationUser) => ({
  type: SET_CURRENT_USER, payload: { user },
});

const authReducer = (state: AuthContextState, action: any) => produce(state, (draftState) => {
  switch (action.type) {
    case SET_SESSION_USER: {
      const { user } = (action as ReturnType<typeof setSessionUser>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.sessionUser = user;
      break;
    }
    case SET_CURRENT_USER: {
      const { user } = (action as ReturnType<typeof setCurrentUser>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.currentUser = user;
      break;
    }
    case STORE_USER_PROFILE: {
      // eslint-disable-next-line no-param-reassign
      draftState.userProfile = action.payload.userProfile;
      break;
    }
    case STORE_USER_PERMISSIONS: {
      // eslint-disable-next-line no-param-reassign
      draftState.userRolePermissions = action.payload.rolePermissions;
      break;
    }
    case SET_AUTHENTICATING: {
      // eslint-disable-next-line no-param-reassign
      draftState.isAuthenticating = action.payload.authenticating;
      break;
    }
    case SET_AUTHENTICATED: {
      // eslint-disable-next-line no-param-reassign
      draftState.isAuthenticated = action.payload.authenticated;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default authReducer;
