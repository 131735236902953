import React, { useState } from 'react';
import produce from 'immer';
import { Popover } from 'custom-test-antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AppMenu } from 'assets/app-menu.svg';
import { RbacPermissionKeys, RbacRoleKeys } from 'constants/rbac';
import { rbacManager } from 'services/rbac-manager/RbacManager';
import { Menu, MenuLabel, MenuTextLabel } from './menu';
import { privateRoutes } from '../../../routes/routesConstants';
import { useAuthState } from '../../../../authentication/context/authContext';
import { userProfileSelector } from '../../../../authentication/context/authSelectors';

import './MenuButton.pcss';

export enum MenuKey {
  Eligibility = 'eligibility',
  AdminClients = 'adminClients',
  PortalCredentials = 'portalCredentials',
  EligibilityDashboard = 'eligibilityDashboard',
  PractitionerLicenses = 'practitionerLicenses',
  EligibilityDefaults = 'eligibilityDefaults'
}

const MenuRoutes: { [key: string]: string } = {
  [MenuKey.EligibilityDashboard]: privateRoutes.eligibilityDashboard,
  [MenuKey.EligibilityDefaults]: privateRoutes.eligibilityDefaultList,
  [MenuKey.PractitionerLicenses]: privateRoutes.practitionerLicensesList,
  [MenuKey.Eligibility]: privateRoutes.eligibilityList,
  [MenuKey.PortalCredentials]: privateRoutes.credentialList,
  [MenuKey.AdminClients]: privateRoutes.adminClient,
};

const prepareItems = (items: any[]) => (
  produce(items, (draft) => draft
    .filter((item) => item?.visible !== false))
    .map((item) => ({ ...item, visible: undefined }))
);

interface Props {
  className?: string;
}

const adminAllowedRoles = [
  RbacRoleKeys.SystemAdmin,
  RbacRoleKeys.SystemUser,
];

const credentialsAllowedRoles = [
  RbacRoleKeys.SystemAdmin,
  RbacRoleKeys.SystemUser,
  RbacRoleKeys.OrganizationAdmin,
  RbacRoleKeys.OrganizationInsuranceManager,
];

export const MenuButton: React.FC<Props> = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const state = useAuthState();
  const userProfile = userProfileSelector(state);
  const actor = userProfile && { id: userProfile.id, roleId: userProfile.rbac_role_id };

  const moduleItems = [
    {
      label: <MenuLabel label="Eligibility" description="View history & check eligibility" />,
      key: MenuKey.Eligibility,
    },
    {
      label: <MenuLabel label="Admin" description="Admin panel" />,
      key: MenuKey.AdminClients,
      visible: userProfile && adminAllowedRoles.includes(userProfile?.rbac_role_id),
    },
  ];

  const toolItems = [
    {
      label: <MenuTextLabel label="Eligibility defaults" />,
      key: MenuKey.EligibilityDefaults,
      visible: userProfile && credentialsAllowedRoles.includes(userProfile?.rbac_role_id),
    },
    {
      label: <MenuTextLabel label="Portal credentials" />,
      key: MenuKey.PortalCredentials,
      visible: userProfile && credentialsAllowedRoles.includes(userProfile?.rbac_role_id),
    },
    {
      label: <MenuTextLabel label="Eligibility dashboard" />,
      key: MenuKey.EligibilityDashboard,
      visible: actor && rbacManager.checkPermission(actor, RbacPermissionKeys.ViewEligibilityUsage),
    },
    {
      label: <MenuTextLabel label="Practitioner Licenses" />,
      key: MenuKey.PractitionerLicenses,
      visible: actor && rbacManager.checkPermission(actor, RbacPermissionKeys.ManagePractionerLicense),
    },
  ];

  const preparedToolItems = prepareItems(toolItems);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const navigate = useNavigate();
  const location = useLocation();
  let selectedKey: string = MenuKey.Eligibility;
  if (location.pathname !== privateRoutes.home) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <Popover
      className="MenuButton"
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="bottomLeft"
      overlayClassName="MenuButton-overlay"
      content={(
        <div>
          <div className="MenuButton-header">Modules</div>
          <Menu
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
              hide();
            }}
            selectedKey={selectedKey}
            items={prepareItems(moduleItems)}
            className="MenuButton-modulesMenu"
          />
          {
            preparedToolItems.length ? (
              <>
                <div className="MenuButton-header">Tools</div>
                <Menu
                  onClick={(e) => {
                    navigate(MenuRoutes[e.key]);
                    hide();
                  }}
                  selectedKey={selectedKey}
                  items={preparedToolItems}
                  className="MenuButton-toolMenu"
                />
              </>
            ) : ''
          }

        </div>
      )}
    >
      <AppMenu className={`${className ?? ''}`} />
    </Popover>
  );
};

export default MenuButton;
