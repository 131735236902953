import { commonFetch, ApiError } from '../apiHelper';

export class OrganizationApi {
  static getOrganizationUsers = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/organization/users`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static requestEligibilityUpgrade = async () => {
    const apiPath = '/v3/organizations/current/billing/request-eligibility-upgrade';
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}${apiPath}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };

  static getOrganizationBranchesCount = async (organizationId: string) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/branches/count`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default OrganizationApi;
