import React, { useEffect, useMemo } from 'react';
import { Spin } from 'custom-test-antd';
import { Routes } from 'react-router-dom';
import container, { Service } from 'container';

import './Application.pcss';

import { UserOnboardingWrapper } from 'components/user-onboarding/UserOnboardingContainer';
import { AuthProvider } from '../authentication/context/authContext';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import { AuthenticatorContainer } from './authenticator';
import { LoadingLayout } from './layouts/loading-layout';

const Application = () => {
  useEffect(() => {
    container.resolve(Service.websocketClient);
  }, []);

  return (
    <AuthProvider>
      <div className="Application">
        <AuthenticatorContainer
          LoadingComponent={useMemo(() => (() => (
            <div className="Application-authSection">
              <LoadingLayout>
                <Spin spinning tip="Authenticating..." />
              </LoadingLayout>
            </div>
          )), [])}
          AuthenticatedComponent={useMemo(() => (() => (
            <UserOnboardingWrapper branchRequired callRequired>
              {PrivateRoutes({})}
            </UserOnboardingWrapper>
          )), [])}
          UnAuthenticatedComponent={useMemo(() => (() => (
            <Routes>
              {PublicRoutes({})}
            </Routes>
          )), [])}
        />
      </div>
    </AuthProvider>
  );
};

export default Application;
