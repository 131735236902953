import { Dispatch } from 'react';

import { AuthenticationApi, UserProfileApi } from 'api';
import { notification } from 'custom-test-antd';
import { rbacManager } from 'services/rbac-manager/RbacManager';
import {
  AuthContextState,
  setAuthenticated,
  setAuthenticating,
  setCurrentUser,
  setSessionUser,
  storeUserPermissions,
  storeUserProfile,
} from './authReducer';
import {
  currentUserSelector,
  isAuthenticatedSelector,
  sessionUserSelector,
  userProfileSelector,
} from './authSelectors';
import container, { Service } from '../../../container';
import { AuthEventType } from '../../../services';
import { privateRoutes } from '../../application/routes/routesConstants';

export const checkUser = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const currentUser = currentUserSelector(state);

  const result = await AuthenticationApi.me(false);
  if (result.sessionUser.id !== sessionUser?.id || result.currentUser.id !== currentUser?.id) {
    window.location.href = privateRoutes.home;
  }
};

export const signOut = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  container.resolve(Service.componentNotification).emit(AuthEventType.UserLogout);
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const userProfile = userProfileSelector(state);
  if (sessionUser?.id && sessionUser?.id !== userProfile?.id) {
    await AuthenticationApi.loginAsUser(null);
    window.location.reload();
    return;
  }
  await AuthenticationApi.signOut();
  dispatch(setAuthenticated(false));
};

export const getUserPermissions = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const userProfile = userProfileSelector(state);
  if (!userProfile) return;

  await rbacManager.loadCache();
  const rolePermissions = rbacManager.getRolePermissions(userProfile.rbac_role_id);

  dispatch(storeUserPermissions(rolePermissions));
};

export const fetchUserProfile = async (dispatch: Dispatch<any>) => {
  const userProfile = await UserProfileApi.getProfile();
  dispatch(storeUserProfile(userProfile));
};

export const authenticate = async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setAuthenticating(true));
    const result = await AuthenticationApi.me();

    await dispatch(fetchUserProfile);
    await dispatch(getUserPermissions);

    dispatch(setSessionUser(result.sessionUser));
    dispatch(setCurrentUser(result.currentUser));
    dispatch(setAuthenticated(true));
    container.resolve(Service.componentNotification).emit(AuthEventType.UserAuthenticated);
  } catch (error) {
    dispatch(setAuthenticated(false));
  } finally {
    dispatch(setAuthenticating(false));
  }
};

export const processUnauthorizedRequest = (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const isAuthenticated = isAuthenticatedSelector(state);
  if (!isAuthenticated) {
    return;
  }
  dispatch(setAuthenticated(false));
  notification.error({
    message: 'Unauthorized',
    description: 'Your access token has expired. Please login again.',
  });
};
