import React from 'react';
import {
  Route,
} from 'react-router-dom';
import { publicRoutes } from './routesConstants';

const SignIn = React.lazy(() => import('../../authentication/sign-in/SignIn'));

export const PublicRoutes: React.FC = () => (
  <>
    <Route
      path={publicRoutes.signIn}
      element={<SignIn />}
    />
    <Route
      path="*"
      element={<SignIn />}
    />
  </>
);

export default PublicRoutes;
