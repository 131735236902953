import React from 'react';
import { Route } from 'react-router-dom';

import { PrivateLayout } from '../layouts/private-layout';
import { PublicRoutes } from './PublicRoutes';
import { privateRoutes } from './routesConstants';

const Admin = React.lazy(() => import('../../admin/Admin'));
const ClientListContainer = React.lazy(() => import('../../admin/client-list/ClientListContainer'));

const Eligibility = React.lazy(() => import('../../eligibility/Eligibility'));
const EligibilityResponseContainer = React.lazy(() => (
  import('../../eligibility/eligibility-response/EligibilityResponseContainer')
));
const EligibilityRequestWrapper = React.lazy(() => (
  import('../../eligibility/eligibility-request/EligibilityRequestContainer')
));
const EligibilityBulk = React.lazy(() => (
  import('../../eligibility/eligibility-bulk/EligibilityBulk')
));
const EligibilityListContainer = React.lazy(() => (
  import('../../eligibility/eligibility-list/EligibilityListContainer')
));

const EligibilityDefault = React.lazy(() => import('../../eligibility-default/EligibilityDefault'));
const EligibilityDefaultCard = React.lazy(() => (
  import('../../eligibility-default/eligibility-default-card/EligibilityDefaultCard')
));
const EligibilityDefaultListContainer = React.lazy(() => (
  import('../../eligibility-default/eligibility-default-list/EligibilityDefaultListContainer')
));

const DashboardContainer = React.lazy(() => (
  import('../../eligibility/dashboard/DashboardContainer')
));

const PractitionerLicenses = React.lazy(() => import('../../practitioner-licenses/PractitionerLicenses'));
const PractitionerLicenseListContainer = React.lazy(() => (
  import('../../practitioner-licenses/license-list/LicenseListContainer')
));
const PractitionerLicenseEditContainer = React.lazy(() => (
  import('../../practitioner-licenses/license-edit/LicenseEditContainer')
));
const PractitionerLicenseNewContainer = React.lazy(() => (
  import('../../practitioner-licenses/license-new/LicenseNewContainer')
));
const PractitionerLicenseBulk = React.lazy(() => (
  import('../../practitioner-licenses/license-bulk/LicenseBulk')
));

const Credentials = React.lazy(() => import('../../credentials/Credentials'));
const CredentialsFormContainer = React.lazy(() => (
  import('../../credentials/credentials-form/CredentialsFormContainer')
));
const CredentialsNewContainer = React.lazy(() => (
  import('../../credentials/credentials-new/CredentialsNewContainer')
));
const CredentialsBulk = React.lazy(() => import('../../credentials/credentials-bulk/CredentialsBulk'));
const CredentialsListContainer = React.lazy(() => (
  import('../../credentials/credentials-list/CredentialsListContainer')
));

const NotFoundPage = React.lazy(() => import('../../not-found/NotFoundPage'));

export const PrivateRoutes: React.FC = () => (
  <>
    <Route
      element={<PrivateLayout />}
    >
      <Route
        path={privateRoutes.home}
        element={<Eligibility />}
      >
        <Route
          path=""
          element={<EligibilityListContainer />}
        />
      </Route>
      <Route
        path={privateRoutes.admin}
        element={<Admin />}
      >
        <Route
          path={privateRoutes.adminClient}
          element={<ClientListContainer />}
        />
      </Route>
      <Route
        path={privateRoutes.eligibilityDashboard}
        element={<DashboardContainer />}
      />
      <Route
        path={privateRoutes.eligibilityDefaultList}
        element={<EligibilityDefault />}
      >
        <Route
          path={privateRoutes.eligibilityDefaultList}
          element={<EligibilityDefaultListContainer />}
        />
        <Route
          path={privateRoutes.eligibilityDefaultNew}
          element={<EligibilityDefaultCard />}
        />
        <Route
          path={privateRoutes.eligibilityDefaultEdit}
          element={<EligibilityDefaultCard />}
        />
      </Route>
      <Route
        path={privateRoutes.eligibilityList}
        element={<Eligibility />}
      >
        <Route
          path={privateRoutes.eligibilityResponse}
          element={<EligibilityResponseContainer />}
        />
        <Route
          path={privateRoutes.eligibilityBulkRequest}
          element={<EligibilityBulk />}
        />

        <Route
          path={privateRoutes.eligibilityRequest}
          element={<EligibilityRequestWrapper />}
        />
        <Route
          path=""
          element={<EligibilityListContainer />}
        />
      </Route>
      <Route
        path={privateRoutes.practitionerLicensesList}
        element={<PractitionerLicenses />}
      >
        <Route
          path={privateRoutes.practitionerLicensesList}
          element={<PractitionerLicenseListContainer />}
        />
        <Route
          path={privateRoutes.practitionerLicensesEdit}
          element={<PractitionerLicenseEditContainer />}
        />
        <Route
          path={privateRoutes.practitionerLicensesNew}
          element={<PractitionerLicenseNewContainer />}
        />
        <Route
          path={privateRoutes.practitionerLicensesBulkUpload}
          element={<PractitionerLicenseBulk />}
        />
      </Route>
      <Route
        path={privateRoutes.credentialList}
        element={<Credentials />}
      >
        <Route
          path={privateRoutes.credentialsForm}
          element={<CredentialsFormContainer />}
        />
        <Route
          path={privateRoutes.credentialsNew}
          element={<CredentialsNewContainer />}
        />
        <Route
          path={privateRoutes.credentialsBulk}
          element={<CredentialsBulk />}
        />
        <Route
          path=""
          element={<CredentialsListContainer />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Route>
    {PublicRoutes({})}
  </>
);

export default PrivateRoutes;
