import React from 'react';

import { isMobile } from '../../../utils/mobileDetector';

import './FinishRegistration.pcss';
import AuthCard from '../auth-card/AuthCard';

export const FinishRegistration: React.FC = () => (
  <div className={`FinishRegistration ${isMobile() ? 'mobile' : ''}`}>
    <AuthCard
      header="You dont have an access"
    >
      Registration needs to be completed. Please contact klaim technical support
    </AuthCard>
  </div>
);

export default FinishRegistration;
