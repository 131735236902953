import React from 'react';
import { useMount } from 'react-use';

import { publicRoutes } from 'components/application/routes/routesConstants';

interface Props {
}

export const SignIn: React.FC<Props> = () => {
  useMount(() => {
    const params = new URLSearchParams({
      returnUrl: btoa(`${window.location.origin}`),
    });
    window.location.href = `${publicRoutes.globalAuthSignIn}?${params}`;
  });
  return null;
};

export default SignIn;
