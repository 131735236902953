import React, { useState } from 'react';
import {
  LogoutOutlined, UserOutlined, UserSwitchOutlined,
} from '@ant-design/icons';
import { Popover, Avatar, Menu, MenuProps, Badge } from 'custom-test-antd';

import './UserInfo.pcss';
import { UserProfile } from 'api/user-profile/userProfileTypes';
import { AuthenticationUser } from 'api/authentication/authenticationTypes';
import { RbacRole } from '../../../../../services/rbac-manager/interfaces';
import { RbacRoleKeys } from '../../../../../constants/rbac';

interface Props {
  user: UserProfile;
  onLogOut: () => void;
  onSettings: () => void;
  onLoginAs: () => void;
  role: RbacRole;
  sessionUser?: AuthenticationUser;
}

type MenuItem = NonNullable<MenuProps['items']>[number];

export const UserInfo: React.FC<Props> = ({
  user, onLogOut, onSettings, role, sessionUser, onLoginAs,
}) => {
  const [visible, setVisible] = useState(false);
  const loginAsOtherUser = sessionUser?.id ? sessionUser.id !== user?.id : false;
  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const menuItems: Array<MenuItem & { visible?: boolean }> = [
    {
      type: 'divider',
    },
    {
      label: 'Profile',
      key: 'profile',
      icon: <UserOutlined />,
      onClick: () => {
        hide();
        onSettings();
      },
    },
    {
      label: 'Login As',
      key: 'loginAs',
      icon: <UserSwitchOutlined />,
      visible: user!.rbac_role_id === RbacRoleKeys.SystemAdmin,
      onClick: () => {
        hide();
        onLoginAs();
      },
    },
    {
      label: 'Sign Out',
      key: 'signOut',
      icon: <LogoutOutlined />,
      onClick: onLogOut,
    }];
  return (
    <Popover
      className="UserInfo"
      trigger="click"
      placement="bottomRight"
      overlayClassName="UserInfo-popoverOverlay"
      open={visible}
      onOpenChange={handleVisibleChange}
      content={(
        <div>
          <div className="UserInfo-popoverHeader">
            <Badge count={loginAsOtherUser ? <UserSwitchOutlined style={{ color: '#f5222d' }} /> : 0}>
              <Avatar size={82} icon={<UserOutlined />} />
            </Badge>
            {
              loginAsOtherUser
              && (
                <>
                  <span className="UserInfo-name">{`Origin: ${sessionUser?.name}`}</span>
                  <span className="UserInfo-email" title={sessionUser?.email}>{sessionUser?.email}</span>
                </>
              )
            }
            <span className="UserInfo-name">{user.name}</span>
            <span className="UserInfo-email" title={user.email}>{user.email}</span>
            <span className="UserInfo-email">{role.name}</span>
          </div>
          <Menu
            className="UserInfo-menu"
            items={
              menuItems
                .filter((item) => item && item.visible !== false)
                .map((item: any) => ({ ...item, visible: undefined }))
            }
          />
        </div>
      )}
    >
      <div>
        <Badge count={loginAsOtherUser ? <UserSwitchOutlined style={{ color: '#f5222d' }} /> : 0}>
          <Avatar className="UserInfo-avatar" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Popover>
  );
};

export default UserInfo;
