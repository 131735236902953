import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spin } from 'custom-test-antd';
import { OnboardStep } from './context/userOnboardingReducer';
import { PrivateLayoutContainer } from '../application/layouts/private-layout/PrivateLayoutContainer';
import { LoadingLayout } from '../application/layouts/loading-layout/LoadingLayout';

const FinishRegistration = React.lazy(() => import('./finish-registration/FinishRegistration'));

interface Props {
  onboardStep?: OnboardStep;
  children?: React.ReactNode;
}

export const UserOnboarding = ({ children, onboardStep }: Props) => {
  if (onboardStep === OnboardStep.Loading) {
    return (
      <Routes>
        <Route
          element={<PrivateLayoutContainer showMenu={false} isMobileAdaptive />}
        >
          <Route
            path="*"
            element={(
              <LoadingLayout>
                <Spin spinning tip="Checking user..." />
              </LoadingLayout>
            )}
          />
        </Route>
      </Routes>
    );
  }
  if (onboardStep && [OnboardStep.ScheduleCall, OnboardStep.EnterBranch].includes(onboardStep)) {
    return (
      <Routes>
        <Route
          element={<PrivateLayoutContainer showMenu={false} isMobileAdaptive />}
        >
          <Route
            path="*"
            element={<FinishRegistration />}
          />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      {children}
    </Routes>
  );
};

export default UserOnboarding;
