import { ApiError, commonFetch, commonNonAuthFetch } from '../apiHelper';
import {
  MeResult,
} from './authenticationTypes';

export class AuthenticationApi {
  static signOut = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/sign-out`;
    const backendResponse = await commonNonAuthFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static me = async (showErrors = true): Promise<MeResult> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/me`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    }, showErrors);
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static loginAsUser = async (userId: string | null): Promise<void> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/represent-as/${userId}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default AuthenticationApi;
