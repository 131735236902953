import EventEmitter from 'eventemitter3';

export enum AuthEventType {
  UnauthorizedRequest = 'UNAUTHORIZED_REQUEST',
  UserAuthenticated = 'USER_AUTHENTICATED',
  UserLogout = 'USER_LOGOUT',
}

export class ComponentNotificationService extends EventEmitter {

}

export default ComponentNotificationService;
