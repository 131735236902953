import { AllUserResult, OrganizationOwner, ChangePasswordAttribute, UserProfile } from './userProfileTypes';
import { commonFetch, ApiError, encodeQueryFilter } from '../apiHelper';

export class UserProfileApi {



  static getProfile = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/profile`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getOrganizationOwners = async (): Promise<OrganizationOwner[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/users/organization-owners`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static setPortalPaid = async (organizationId: number, isPaid: boolean): Promise<any> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/set-portal-paid`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isPaid }),
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static setEligibilityPaid = async (organizationId: number, isPaid: boolean): Promise<any> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/set-eligibility-paid`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isPaid }),
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static setInspectorPaid = async (organizationId: number, isPaid: boolean): Promise<any> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/set-inspector-paid`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isPaid }),
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default UserProfileApi;
