export const publicRoutes = {
  globalAuth: `${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}`,
  globalAuthSignIn: `${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}/sign-in`,
  signIn: '/sign-in',
};

export const privateRoutes = {
  home: '/',

  portalLoginAs: `${process.env.REACT_APP_PORTAL_SERVICE_URL}/settings/login-as`,
  eligibilityList: '/eligibility',
  eligibilityResponse: '/eligibility/response/:eligibilityId',
  eligibilityRequest: '/eligibility/request',
  eligibilityBulkRequest: '/eligibility/bulk-request',

  practitionerLicensesList: '/practitioner-licenses',
  practitionerLicensesEdit: '/practitioner-licenses/:licenseId',
  practitionerLicensesNew: '/practitioner-licenses/new',
  practitionerLicensesBulkUpload: '/practitioner-licenses/bulk',

  eligibilityDefaultList: '/eligibility-defaults',
  eligibilityDefaultNew: '/eligibility-defaults/new',
  eligibilityDefaultEdit: '/eligibility-defaults/:id',

  eligibilityDashboard: '/eligibility-dashboard',

  adminClient: '/admin/clients',
  admin: '/admin',

  credentialList: '/credentials',
  credentialsForm: '/credentials/:credentialsId',
  credentialsNew: '/credentials/new',
  credentialsBulk: '/credentials/bulk',
};
