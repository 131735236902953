import React, { useCallback } from 'react';

import {
  sessionUserSelector,
  userProfileSelector,
  userRolePermissionsSelector,
} from 'components/authentication/context/authSelectors';
import { useAuthDispatch, useAuthState } from 'components/authentication/context/authContext';
import { signOut } from 'components/authentication/context/authThunks';
import { publicRoutes, privateRoutes } from 'components/application/routes/routesConstants';
import { UserProfile } from 'api/user-profile/userProfileTypes';

import UserInfo from './UserInfo';
import { RbacRole } from '../../../../../services/rbac-manager/interfaces';

export const UserInfoContainer: React.FC = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  return (
    <UserInfo
      sessionUser={sessionUserSelector(state)}
      user={userProfileSelector(state) as UserProfile}
      role={userRolePermissionsSelector(state) as RbacRole}
      onLogOut={() => {
        dispatch(signOut);
      }}
      onSettings={useCallback(() => {
        const params = new URLSearchParams({ returnUrl: btoa(window.location.href) });
        window.location.href = `${publicRoutes.globalAuth}?${params}`;
      }, [])}
      onLoginAs={useCallback(() => {
        const params = new URLSearchParams({ returnUrl: btoa(window.location.href) });
        window.location.href = `${privateRoutes.portalLoginAs}?${params}`;
      }, [])}
    />
  );
};

export default UserInfoContainer;
